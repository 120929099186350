import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function purchaseOrderCycleDetailList() {

  // Use toast
  const toast = useToast()
  const orderNo = ref('')
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const refetchData = () => {
    refListTable1.value.refresh()
    refListTable2.value.refresh()
    refListTable3.value.refresh()
    refListTable4.value.refresh()
    refListTable5.value.refresh()
    refListTable6.value.refresh()
    refListTable7.value.refresh()
    refListTable8.value.refresh()
    refListTable9.value.refresh()
    refListTable10.value.refresh()
    refListTable11.value.refresh()
    refListTable12.value.refresh()
    refListTable13.value.refresh()
    refListTable14.value.refresh()
    refListTable15.value.refresh()
    refListTable16.value.refresh()
    refListTable17.value.refresh()
    refListTable18.value.refresh()
    refListTable19.value.refresh()
  }
  /*采购单明细*/
  const refListTable1 = ref(null)
  // Table Handlers
  const tableColumns1 = [
    { key: 'orderId', label: '采购单Id', sortable: true },
    { key: 'orderNo', label: '采购单编号', sortable: true },
    { key: 'supplierId', label: '供应商Id', sortable: true },
    { key: 'supplierName', label: '供应商名称', sortable: true },
    { key: 'contractId', label: '合同Id', sortable: true },
    { key: 'contractNo', label: '合同编号', sortable: true },
    { key: 'totalPaid', label: '已付金额', sortable: true },
    { key: 'totalPay', label: '已到货金额', sortable: true },
    { key: 'totalQty', label: '订单总数量', sortable: true },
    { key: 'totalDeliveryQty', label: '已发货数量', sortable: true },
    { key: 'status', label: '审核状态', sortable: true },
    { key: 'statusPay', label: '付款状态', sortable: true },
    { key: 'itemId', label: '采购单明细Id', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'qty', label: '采购数量', sortable: true },
    { key: 'productCostTax', label: '含税单价', sortable: true },
  ]

  const start1 = ref(1)
  const limitOption1 = [1,2,3]
  const listTotals1 = ref(0)
  const orderBy1 = ref('orderId')
  const isSortDirDesc1 = ref(true)

  const dataMeta1 = computed(() => {
    const localItemsCount = refListTable1.value ? refListTable1.value.localItems.length : 0
    return {
      from: limit.value * (start1.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start1.value - 1) + localItemsCount,
      of: listTotals1.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start1, limit], () => {
    refListTable1.value.refresh()
  })
  const dataList1 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listPurchaseOrderDetail', {
          start: start1.value,
          limit: limit.value,
          order_by: orderBy1.value,
          order_desc: isSortDirDesc1.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals1.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购单明细列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  /*采购入库单明细*/
  const refListTable2 = ref(null)
  // Table Handlers
  const tableColumns2 = [
    { key: 'orderId', label: '采购单Id', sortable: true },
    { key: 'orderNo', label: '采购单编号', sortable: true },
    { key: 'inboundId', label: '入库单Id', sortable: true },
    { key: 'inboundNo', label: '入库单编号', sortable: true },
    { key: 'warehouseId', label: '入库仓库Id', sortable: true },
    { key: 'warehouseName', label: '入库仓库名称', sortable: true },
    { key: 'status', label: '入库状态', sortable: true },
    { key: 'orderitemId', label: '采购入库单明细Id', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'isGift', label: '是否赠品', sortable: true },
    { key: 'qty', label: '计划入库数量', sortable: true },
    { key: 'trueqty', label: '实际入库数量', sortable: true },
    { key: 'inventoryType', label: '盘点类型', sortable: true },
    { key: 'inboundTime', label: '入库时间', sortable: true },
  ]

  const start2 = ref(1)
  const limitOption2 = [1,2,3]
  const listTotals2 = ref(0)
  const orderBy2 = ref()
  const isSortDirDesc2 = ref(true)

  const dataMeta2 = computed(() => {
    const localItemsCount = refListTable2.value ? refListTable2.value.localItems.length : 0
    return {
      from: limit.value * (start2.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start2.value - 1) + localItemsCount,
      of: listTotals2.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start2, limit], () => {
    refListTable2.value.refresh()
  })
  const dataList2 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listPurchaseOrderInboundDetail', {
          start: start2.value,
          limit: limit.value,
          order_by: orderBy2.value,
          order_desc: isSortDirDesc2.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals2.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购入库单明细列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  /*采购单付款单明细*/
  const refListTable3 = ref(null)
  // Table Handlers
  const tableColumns3 = [
    { key: 'fPaymentNo', label: '付款单编号', sortable: true },
    { key: 'businessDate', label: '业务日期', sortable: true },
    { key: 'fPaymentType', label: '单据类型', sortable: true },
    { key: 'businessUnitId', label: '往来单位Id', sortable: true },
    { key: 'businessUnitName', label: '往来单位名称', sortable: true },
    { key: 'payeeId', label: '收款单位Id', sortable: true },
    { key: 'payeeName', label: '收款单位名称', sortable: true },
    { key: 'paymentOrgId', label: '付款主体Id', sortable: true },
    { key: 'paymentOrgName', label: '付款主体名称', sortable: true },
    { key: 'purchaseOrgId', label: '采购主体Id', sortable: true },
    { key: 'purchaseOrgName', label: '采购主体名称', sortable: true },
    { key: 'orderId', label: '采购单Id', sortable: true },
    { key: 'orderNo', label: '采购单编号', sortable: true },
    { key: 'payableAmount', label: '应付金额', sortable: true },
    { key: 'paidAmount', label: '实付金额', sortable: true },
    { key: 'status', label: '审核状态', sortable: true },
  ]

  const start3 = ref(1)
  const limitOption3 = [1,2,3]
  const listTotals3 = ref(0)
  const orderBy3 = ref()
  const isSortDirDesc3 = ref(true)

  const dataMeta3 = computed(() => {
    const localItemsCount = refListTable3.value ? refListTable3.value.localItems.length : 0
    return {
      from: limit.value * (start3.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start3.value - 1) + localItemsCount,
      of: listTotals3.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start3, limit], () => {
    refListTable3.value.refresh()
  })

  const dataList3 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listPurchaseOrderPaymentDetail', {
          start: start3.value,
          limit: limit.value,
          order_by: orderBy3.value,
          order_desc: isSortDirDesc3.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals3.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购单付款单明细列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*生成采购单的退货单明细*/
  const refListTable4 = ref(null)
  // Table Handlers
  const tableColumns4 = [
    { key: 'returnboundId', label: '退货单Id', sortable: true },
    { key: 'returnboundNo', label: '退货单编号', sortable: true },
    { key: 'storeId', label: '店铺Id', sortable: true },
    { key: 'storeName', label: '店铺名称', sortable: true },
    { key: 'salesId', label: '销售员Id', sortable: true },
    { key: 'salesName', label: '销售员', sortable: true },
    { key: 'warehouseId', label: '入库仓库Id', sortable: true },
    { key: 'warehouseName', label: '入库仓库', sortable: true },
    { key: 'status', label: '入库状态', sortable: true },
    { key: 'returnTime', label: '退货日期', sortable: true },
    { key: 'returnboundTime', label: '退货入库时间', sortable: true },
    { key: 'returnboundCreator', label: '退货入库人', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: '库存Id', sortable: true },
    { key: 'salesreturnqty', label: '预计退货数量', sortable: true },
    { key: 'salesreturnqtyGood', label: '实际退货数量(良品)', sortable: true },
    { key: 'salesreturnqtyBad', label: '实际退货数量(不良品)', sortable: true },
    { key: 'realreturnQty', label: '实际退货数量', sortable: true },
    { key: 'productCostTax', label: '退货金额', sortable: true },
    { key: 'costPrice', label: '销售单价格', sortable: true },
    { key: 'isStatement', label: '是否已生成退货对账单', sortable: true },
    { key: 'preOrderId', label: '前置销售单Id', sortable: true },
    { key: 'preItemId', label: '前置销售明细Id', sortable: true },
  ]

  const start4 = ref(1)
  const limitOption4 = [1,2,3]
  const listTotals4 = ref(0)
  const orderBy4 = ref("returnboundId")
  const isSortDirDesc4 = ref(true)

  const dataMeta4 = computed(() => {
    const localItemsCount = refListTable4.value ? refListTable4.value.localItems.length : 0
    return {
      from: limit.value * (start4.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start4.value - 1) + localItemsCount,
      of: listTotals4.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start4, limit], () => {
    refListTable4.value.refresh()
  })

  const dataList4 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listPurchaseOrderReturnDetail', {
          start: start4.value,
          limit: limit.value,
          order_by: orderBy4.value,
          order_desc: isSortDirDesc4.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals4.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购单的退货单明细列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*生成采购单的退货单对应的前置退货单*/
  const refListTable5 = ref(null)
  // Table Handlers
  const tableColumns5 = [
    { key: 'returnboundId', label: '前置退货单Id', sortable: true },
    { key: 'returnboundNo', label: '前置退货单编号', sortable: true },
    { key: 'channelId', label: '渠道Id', sortable: true },
    { key: 'channelName', label: '渠道', sortable: true },
    { key: 'warehouseId', label: '入库仓库Id', sortable: true },
    { key: 'warehouseName', label: '入库仓库', sortable: true },
    { key: 'storeId', label: '店铺Id', sortable: true },
    { key: 'storeName', label: '店铺', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'salesId', label: '销售员Id', sortable: true },
    { key: 'salesName', label: '销售员', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'code', label: '商品编码', sortable: true },
    { key: 'returnPrice', label: '销售录入的退货价格', sortable: true },
    { key: 'returnQty', label: '退货数量', sortable: true },
    { key: 'createReturnboundId', label: '生成的退货单Id', sortable: true },
  ]

  const start5 = ref(1)
  const limitOption5 = [1,2,3]
  const listTotals5 = ref(0)
  const orderBy5 = ref()
  const isSortDirDesc5 = ref(true)

  const dataMeta5 = computed(() => {
    const localItemsCount = refListTable5.value ? refListTable5.value.localItems.length : 0
    return {
      from: limit.value * (start5.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start5.value - 1) + localItemsCount,
      of: listTotals5.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start5, limit], () => {
    refListTable5.value.refresh()
  })

  const dataList5 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listPurchaseOrderReturnBeforeOrder', {
          start: start5.value,
          limit: limit.value,
          order_by: orderBy5.value,
          order_desc: isSortDirDesc5.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals5.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '生成采购单的退货单对应的前置退货单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*采购入库单入库明细*/
  const refListTable6 = ref(null)
  // Table Handlers
  const tableColumns6 = [
    { key: 'orderId', label: '采购单Id', sortable: true },
    { key: 'orderNo', label: '采购单编号', sortable: true },
    { key: 'warehouseId', label: '入库仓库Id', sortable: true },
    { key: 'warehouseName', label: '入库仓库', sortable: true },
    { key: 'inboundId', label: '入库单Id', sortable: true },
    { key: 'inboundNo', label: '入库单编号', sortable: true },
    { key: 'inbounditemId', label: '入库单明细Id', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'qty', label: '入库数量', sortable: true },
    { key: 'cost', label: '入库时成本', sortable: true },
    { key: 'status', label: '库存状态', sortable: true },
    { key: 'stockId', label: '库存Id', sortable: true },
    { key: 'addTime', label: '操作时间', sortable: true },
    { key: 'creator', label: '操作人', sortable: true },
  ]

  const start6 = ref(1)
  const limitOption6 = [1,2,3]
  const listTotals6 = ref(0)
  const orderBy6 = ref()
  const isSortDirDesc6 = ref(true)

  const dataMeta6 = computed(() => {
    const localItemsCount = refListTable6.value ? refListTable6.value.localItems.length : 0
    return {
      from: limit.value * (start6.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start6.value - 1) + localItemsCount,
      of: listTotals6.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start6, limit], () => {
    refListTable6.value.refresh()
  })

  const dataList6 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listPurchaseInboundDetail', {
          start: start6.value,
          limit: limit.value,
          order_by: orderBy6.value,
          order_desc: isSortDirDesc6.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals6.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购入库单入库明细列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*采购入库单库存明细*/
  const refListTable7 = ref(null)
  // Table Handlers
  const tableColumns7 = [
    { key: 'stockId', label: '库存Id', sortable: true },
    { key: 'warehouseId', label: '所在仓库Id', sortable: true },
    { key: 'warehouseName', label: '所在仓库', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'isGift', label: '是否赠品', sortable: true },
    { key: 'purchaseorderId', label: '原始采购单Id', sortable: true },
    { key: 'purchaseorderItemid', label: '原始采购单明细Id', sortable: true },
    { key: 'cost', label: '成本', sortable: true },
    { key: 'qty', label: '数量', sortable: true },
    { key: 'status', label: '库存状态', sortable: true },
    { key: 'isVmi', label: '是否VMI库存', sortable: true },
    { key: 'modifyTime', label: '最后更新时间', sortable: true },
    { key: 'updator', label: '最后更新人', sortable: true },
  ]

  const start7 = ref(1)
  const limitOption7 = [1,2,3]
  const listTotals7 = ref(0)
  const orderBy7 = ref()
  const isSortDirDesc7 = ref(true)

  const dataMeta7 = computed(() => {
    const localItemsCount = refListTable7.value ? refListTable7.value.localItems.length : 0
    return {
      from: limit.value * (start7.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start7.value - 1) + localItemsCount,
      of: listTotals7.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start7, limit], () => {
    refListTable7.value.refresh()
  })

  const dataList7 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listPurchaseInboundStockDetail', {
          start: start7.value,
          limit: limit.value,
          order_by: orderBy7.value,
          order_desc: isSortDirDesc7.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals7.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购入库单库存明细列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*由采购单生成的采购成本调价单*/
  const refListTable8 = ref(null)
  // Table Handlers
  const tableColumns8 = [
    { key: 'changeId', label: '调价单Id', sortable: true },
    { key: 'changeNo', label: '调价单编号', sortable: true },
    { key: 'orderId', label: '采购单Id', sortable: true },
    { key: 'orderNo', label: '采购单编号', sortable: true },
    { key: 'itemId', label: '采购单明细Id', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'productCostTaxOld', label: '修改前含税单价', sortable: true },
    { key: 'productCostTaxNew', label: '修改后含税单价', sortable: true },
    { key: 'qty', label: '数量', sortable: true },
    { key: 'memo', label: '备注', sortable: true },
    { key: 'status', label: '状态', sortable: true },
  ]

  const start8 = ref(1)
  const limitOption8 = [1,2,3]
  const listTotals8 = ref(0)
  const orderBy8 = ref()
  const isSortDirDesc8 = ref(true)

  const dataMeta8 = computed(() => {
    const localItemsCount = refListTable8.value ? refListTable8.value.localItems.length : 0
    return {
      from: limit.value * (start8.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start8.value - 1) + localItemsCount,
      of: listTotals8.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start8, limit], () => {
    refListTable8.value.refresh()
  })

  const dataList8 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listPurchaseOrderChangePriceDetail', {
          start: start8.value,
          limit: limit.value,
          order_by: orderBy8.value,
          order_desc: isSortDirDesc8.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals8.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '由采购单生成的采购成本调价单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*由采购单生成的采购退料单*/
  const refListTable9 = ref(null)
  // Table Handlers
  const tableColumns9 = [
    { key: 'outboundId', label: '退料单Id', sortable: true },
    { key: 'outboundNo', label: '退料单编号', sortable: true },
    { key: 'returnboundId', label: '退货单Id', sortable: true },
    { key: 'returnboundNo', label: '退货单编号', sortable: true },
    { key: 'orderId', label: '采购单Id', sortable: true },
    { key: 'orderNo', label: '采购单编号', sortable: true },
    { key: 'warehouseId', label: '仓库Id', sortable: true },
    { key: 'warehouseName', label: '仓库名称', sortable: true },
    { key: 'reason', label: '退料原因', sortable: true },
    { key: 'returnTime', label: '退货日期', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'inboundId', label: '入库单Id', sortable: true },
    { key: 'inboundNo', label: '入库单编号', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: '库存Id', sortable: true },
    { key: 'warestatus', label: '库存状态', sortable: true },
    { key: 'qty', label: '计划退货数量', sortable: true },
    { key: 'trueqty', label: '实际退货数量', sortable: true },
  ]

  const start9 = ref(1)
  const limitOption9 = [1,2,3]
  const listTotals9 = ref(0)
  const orderBy9 = ref()
  const isSortDirDesc9 = ref(true)

  const dataMeta9 = computed(() => {
    const localItemsCount = refListTable9.value ? refListTable9.value.localItems.length : 0
    return {
      from: limit.value * (start9.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start9.value - 1) + localItemsCount,
      of: listTotals9.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start9, limit], () => {
    refListTable9.value.refresh()
  })

  const dataList9 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listPurchaseReturnDetail', {
          start: start9.value,
          limit: limit.value,
          order_by: orderBy9.value,
          order_desc: isSortDirDesc9.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals9.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '由采购单生成的采购退料单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  //采购入库单库存明细生成的箱规转换明细(作为转换前库存)
  const refListTable10 = ref(null)
  // Table Handlers
  const tableColumns10 = [
    { key: 'changeNo', label: '转换单编号', sortable: true },
    { key: 'applierName', label: '申请人', sortable: true },
    { key: 'type', label: '转换类型', sortable: true },
    { key: 'warehouseName', label: '所在仓库', sortable: true },
    { key: 'sourceProductId', label: '转换前商品Id', sortable: true },
    { key: 'sourceName', label: '转换前商品名称', sortable: true },
    { key: 'sourceCode', label: '转换前商品编码', sortable: true },
    { key: 'sourceBoxQuantity', label: '转换前商品箱规', sortable: true },
    { key: 'sourceStockId', label: '转换前商品库存Id', sortable: true },
    { key: 'sourceCost', label: '转换前商品成本', sortable: true },
    { key: 'sourceBeforeqty', label: '待转商品数量', sortable: true },
    { key: 'convertProductId', label: '转换后商品Id', sortable: true },
    { key: 'convertName', label: '转换后商品名称', sortable: true },
    { key: 'convertCode', label: '转换后商品编码', sortable: true },
    { key: 'convertBoxQuantity', label: '转换后商品箱规', sortable: true },
    { key: 'convertStockId', label: '转换后商品库存Id', sortable: true },
    { key: 'convertCost', label: '转换后商品成本', sortable: true },
    { key: 'convertBeforeqty', label: '转换后商品数量', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'state', label: '是否有效', sortable: true },
    { key: 'createTime', label: '发生时间', sortable: true },
  ]

  const start10 = ref(1)
  const limitOption10 = [1,2,3]
  const listTotals10 = ref(0)
  const orderBy10 = ref()
  const isSortDirDesc10 = ref(true)

  const dataMeta10 = computed(() => {
    const localItemsCount = refListTable10.value ? refListTable10.value.localItems.length : 0
    return {
      from: limit.value * (start10.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start10.value - 1) + localItemsCount,
      of: listTotals10.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start10, limit], () => {
    refListTable10.value.refresh()
  })

  const dataList10 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listBeforeBoxChangeDetail', {
          start: start10.value,
          limit: limit.value,
          order_by: orderBy10.value,
          order_desc: isSortDirDesc10.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals10.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购入库单库存明细生成的箱规转换明细(作为转换前库存)列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*采购入库单库存明细生成的箱规转换明细(作为转换后库存)*/
  const refListTable11 = ref(null)
  // Table Handlers
  const tableColumns11 = [
    { key: 'changeNo', label: '转换单编号', sortable: true },
    { key: 'applierName', label: '申请人', sortable: true },
    { key: 'type', label: '转换类型', sortable: true },
    { key: 'warehouseName', label: '所在仓库', sortable: true },
    { key: 'sourceProductId', label: '转换前商品Id', sortable: true },
    { key: 'sourceName', label: '转换前商品名称', sortable: true },
    { key: 'sourceCode', label: '转换前商品编码', sortable: true },
    { key: 'sourceBoxQuantity', label: '转换前商品箱规', sortable: true },
    { key: 'sourceStockId', label: '转换前商品库存Id', sortable: true },
    { key: 'sourceCost', label: '转换前商品成本', sortable: true },
    { key: 'sourceBeforeqty', label: '待转商品数量', sortable: true },
    { key: 'convertProductId', label: '转换后商品Id', sortable: true },
    { key: 'convertName', label: '转换后商品名称', sortable: true },
    { key: 'convertCode', label: '转换后商品编码', sortable: true },
    { key: 'convertBoxQuantity', label: '转换后商品箱规', sortable: true },
    { key: 'convertStockId', label: '转换后商品库存Id', sortable: true },
    { key: 'convertCost', label: '转换后商品成本', sortable: true },
    { key: 'convertBeforeqty', label: '转换后商品数量', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'state', label: '是否有效', sortable: true },
    { key: 'createTime', label: '发生时间', sortable: true },
  ]

  const start11 = ref(1)
  const limitOption11 = [1,2,3]
  const listTotals11 = ref(0)
  const orderBy11 = ref()
  const isSortDirDesc11 = ref(true)

  const dataMeta11 = computed(() => {
    const localItemsCount = refListTable11.value ? refListTable11.value.localItems.length : 0
    return {
      from: limit.value * (start11.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start11.value - 1) + localItemsCount,
      of: listTotals11.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start11, limit], () => {
    refListTable11.value.refresh()
  })

  const dataList11 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listAfterBoxChangeDetail', {
          start: start11.value,
          limit: limit.value,
          order_by: orderBy11.value,
          order_desc: isSortDirDesc11.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals11.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购入库单库存明细生成的箱规转换明细(作为转换后库存)列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*采购入库单库存明细生成的库位转换明细*/
  const refListTable12 = ref(null)
  // Table Handlers
  const tableColumns12 = [
    { key: 'changeNo', label: '转换编号', sortable: true },
    { key: 'stockId', label: '库存Id', sortable: true },
    { key: 'stockQty', label: '被转库存数量', sortable: true },
    { key: 'stockLocation', label: '转换前库位', sortable: true },
    { key: 'goodstock', label: '转换后良品库存数量', sortable: true },
    { key: 'goodlocation', label: '转换后良品库位', sortable: true },
    { key: 'badstock', label: '转换后不良品库存数量', sortable: true },
    { key: 'badlocation', label: '转换后不良品库位', sortable: true },
    { key: 'reason', label: '申请原因', sortable: true },
    { key: 'status', label: '审核状态', sortable: true },
  ]

  const start12 = ref(1)
  const limitOption12 = [1,2,3]
  const listTotals12 = ref(0)
  const orderBy12 = ref()
  const isSortDirDesc12 = ref(true)

  const dataMeta12 = computed(() => {
    const localItemsCount = refListTable12.value ? refListTable12.value.localItems.length : 0
    return {
      from: limit.value * (start12.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start12.value - 1) + localItemsCount,
      of: listTotals12.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start12, limit], () => {
    refListTable12.value.refresh()
  })

  const dataList12 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listShelfChnageDetail', {
          start: start12.value,
          limit: limit.value,
          order_by: orderBy12.value,
          order_desc: isSortDirDesc12.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals12.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购入库单库存明细生成的库位转换明细列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*由采购单产生的回滚单*/
  const refListTable13 = ref(null)
  // Table Handlers
  const tableColumns13 = [
    { key: 'rollbackNo', label: '回滚单号', sortable: true },
    { key: 'relationalId', label: '采购单Id', sortable: true },
    { key: 'relationalNo', label: '采购单编号', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'createTime', label: '申请时间', sortable: true },
    { key: 'creator', label: '申请人', sortable: true },
    { key: 'approveTime', label: '审批时间', sortable: true },
    { key: 'approver', label: '审批人', sortable: true },
  ]

  const start13 = ref(1)
  const limitOption13 = [1,2,3]
  const listTotals13 = ref(0)
  const orderBy13 = ref()
  const isSortDirDesc13 = ref(true)

  const dataMeta13 = computed(() => {
    const localItemsCount = refListTable13.value ? refListTable13.value.localItems.length : 0
    return {
      from: limit.value * (start13.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start13.value - 1) + localItemsCount,
      of: listTotals13.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start13, limit], () => {
    refListTable13.value.refresh()
  })

  const dataList13 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listRollbackOfPurchase', {
          start: start13.value,
          limit: limit.value,
          order_by: orderBy13.value,
          order_desc: isSortDirDesc13.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals13.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '由采购单产生的回滚单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*由采购入库单库存产生的调拨单明细*/
  const refListTable14 = ref(null)
  // Table Handlers
  const tableColumns14 = [
    { key: 'outboundId', label: '调拨单Id', sortable: true },
    { key: 'outboundNo', label: '调拨单编号', sortable: true },
    { key: 'fromwarehouseId', label: '调出仓库Id', sortable: true },
    { key: 'fromwarehouseName', label: '调出仓库', sortable: true },
    { key: 'towarehouseId', label: '调入仓库Id', sortable: true },
    { key: 'towarehouseName', label: '调入仓库', sortable: true },
    { key: 'outbounditemId', label: '调拨单明细Id', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: '库存Id', sortable: true },
    { key: 'warestatus', label: '库存状态', sortable: true },
    { key: 'qty', label: '计划调出数量', sortable: true },
    { key: 'trueqty', label: '实际调出数量', sortable: true },
    { key: 'receiveqty', label: '实际调入数量', sortable: true },
    { key: 'mainStatus', label: '主调拨单状态', sortable: true },
    { key: 'subStatus', label: '子调拨单状态', sortable: true },
  ]

  const start14 = ref(1)
  const limitOption14 = [1,2,3]
  const listTotals14 = ref(0)
  const orderBy14 = ref("outboundId")
  const isSortDirDesc14 = ref(true)

  const dataMeta14 = computed(() => {
    const localItemsCount = refListTable14.value ? refListTable14.value.localItems.length : 0
    return {
      from: limit.value * (start14.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start14.value - 1) + localItemsCount,
      of: listTotals14.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start14, limit], () => {
    refListTable14.value.refresh()
  })

  const dataList14 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listRequisitionDetailOfPurchaseInbound', {
          start: start14.value,
          limit: limit.value,
          order_by: orderBy14.value,
          order_desc: isSortDirDesc14.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals14.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '由采购入库单库存产生的调拨单明细列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*由采购单产生的销售单*/
  const refListTable15 = ref(null)
  // Table Handlers
  const tableColumns15 = [
    { key: 'orderId', label: '销售单Id', sortable: true },
    { key: 'orderNo', label: '销售单编号', sortable: true },
    { key: 'typeId', label: '业务类型', sortable: true },
    { key: 'channelId', label: '渠道Id', sortable: true },
    { key: 'channelName', label: '渠道名称', sortable: true },
    { key: 'salesTime', label: '售卖日期', sortable: true },
    { key: 'salesId', label: '销售员Id', sortable: true },
    { key: 'salesName', label: '销售员', sortable: true },
    { key: 'storeId', label: '店铺Id', sortable: true },
    { key: 'storeName', label: '店铺名称', sortable: true },
    { key: 'status', label: '订单状态', sortable: true },
    { key: 'salesWarehouse', label: '售卖仓库', sortable: true },
    { key: 'isStatement', label: '是否已生成销售对账单', sortable: true },
    { key: 'isInvoice', label: '是否已开票', sortable: true },
    { key: 'itemId', label: '销售单明细Id', sortable: true },
    { key: 'stockId', label: '库存Id', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'isGift', label: '是否赠品', sortable: true },
    { key: 'costPrice', label: '采购单含税单价', sortable: true },
    { key: 'productCostTax', label: '售卖价格', sortable: true },
    { key: 'taxRate', label: '税率', sortable: true },
    { key: 'qty', label: '销售数量', sortable: true },
    { key: 'subtotal', label: '价税合计', sortable: true },
    { key: 'preOrderId', label: '前置销售单Id', sortable: true },
    { key: 'preItemId', label: '前置销售单明细', sortable: true },
    { key: 'isSendbi', label: '是否已推送BI', sortable: true },
  ]

  const start15 = ref(1)
  const limitOption15 = [1,2,3]
  const listTotals15 = ref(0)
  const orderBy15 = ref("orderId")
  const isSortDirDesc15 = ref(true)

  const dataMeta15 = computed(() => {
    const localItemsCount = refListTable15.value ? refListTable15.value.localItems.length : 0
    return {
      from: limit.value * (start15.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start15.value - 1) + localItemsCount,
      of: listTotals15.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start15, limit], () => {
    refListTable15.value.refresh()
  })

  const dataList15 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listSalesOrderOfPurchase', {
          start: start15.value,
          limit: limit.value,
          order_by: orderBy15.value,
          order_desc: isSortDirDesc15.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals15.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '由采购单产生的销售单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*由采购单产生的前置销售单*/
  const refListTable16 = ref(null)
  // Table Handlers
  const tableColumns16 = [
    { key: 'orderId', label: '前置销售单Id', sortable: true },
    { key: 'orderNo', label: '前置销售单编号', sortable: true },
    { key: 'typeId', label: '订单类型', sortable: true },
    { key: 'source', label: '订单来源', sortable: true },
    { key: 'channelId', label: '渠道id', sortable: true },
    { key: 'channelName', label: '渠道名称', sortable: true },
    { key: 'storeId', label: '店铺Id', sortable: true },
    { key: 'storeName', label: '店铺名称', sortable: true },
    { key: 'salesTime', label: '售卖日期', sortable: true },
    { key: 'salesId', label: '销售员Id', sortable: true },
    { key: 'salesName', label: '销售员', sortable: true },
    { key: 'addTime', label: '创建时间', sortable: true },
    { key: 'isReceipt', label: '是否已回执', sortable: true },
    { key: 'receiptTime', label: '回执时间', sortable: true },
    { key: 'isCheckout', label: '是否已出库', sortable: true },
    { key: 'checkTime', label: '出库时间', sortable: true },
    { key: 'isInvoice', label: '是否已开票', sortable: true },
    { key: 'salesWarehouse', label: '售卖仓库Id', sortable: true },
    { key: 'warehouseName', label: '售卖仓库', sortable: true },
    { key: 'status', label: '订单状态', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'salesPrice', label: '销售员定的价格', sortable: true },
    { key: 'salesQty', label: '销售员填的销售数量', sortable: true },
    { key: 'refuseQty', label: '拒收数量', sortable: true },
    { key: 'createOrderId', label: '生成的销售单Id', sortable: true },
  ]

  const start16 = ref(1)
  const limitOption16 = [1,2,3]
  const listTotals16 = ref(0)
  const orderBy16 = ref("orderId")
  const isSortDirDesc16 = ref(true)

  const dataMeta16 = computed(() => {
    const localItemsCount = refListTable16.value ? refListTable16.value.localItems.length : 0
    return {
      from: limit.value * (start16.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start16.value - 1) + localItemsCount,
      of: listTotals16.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start16, limit], () => {
    refListTable16.value.refresh()
  })

  const dataList16 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listBeforeSalesOrderOfPurchase', {
          start: start16.value,
          limit: limit.value,
          order_by: orderBy16.value,
          order_desc: isSortDirDesc16.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals16.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '由采购单产生的前置销售单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*由采购单产生的前置销售单生成的退货单*/
  const refListTable17 = ref(null)
  // Table Handlers
  const tableColumns17 = [
    { key: 'returnboundId', label: '退货单Id', sortable: true },
    { key: 'returnboundNo', label: '退货单编号', sortable: true },
    { key: 'storeId', label: '店铺Id', sortable: true },
    { key: 'storeName', label: '店铺名称', sortable: true },
    { key: 'salesId', label: '销售员Id', sortable: true },
    { key: 'salesName', label: '销售员', sortable: true },
    { key: 'warehouseId', label: '入库仓库Id', sortable: true },
    { key: 'warehouseName', label: '入库仓库', sortable: true },
    { key: 'status', label: '入库状态', sortable: true },
    { key: 'returnTime', label: '退货日期', sortable: true },
    { key: 'returnboundTime', label: '退货入库时间', sortable: true },
    { key: 'returnboundCreator', label: '退货入库人', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: '库存Id', sortable: true },
    { key: 'salesreturnqty', label: '预计退货数量', sortable: true },
    { key: 'salesreturnqtyGood', label: '实际退货数量(良品)', sortable: true },
    { key: 'salesreturnqtyBad', label: '实际退货数量(不良品)', sortable: true },
    { key: 'RealreturnQty', label: '实际退货数量', sortable: true },
    { key: 'productCostTax', label: '退货金额', sortable: true },
    { key: 'costPrice', label: '销售单价格', sortable: true },
    { key: 'isStatement', label: '是否已生成退货对账单', sortable: true },
    { key: 'preOrderId', label: '前置销售单Id', sortable: true },
    { key: 'preItemId', label: '前置销售明细Id', sortable: true },
  ]

  const start17 = ref(1)
  const limitOption17 = [1,2,3]
  const listTotals17 = ref(0)
  const orderBy17 = ref("returnboundId")
  const isSortDirDesc17 = ref(true)

  const dataMeta17 = computed(() => {
    const localItemsCount = refListTable17.value ? refListTable17.value.localItems.length : 0
    return {
      from: limit.value * (start17.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start17.value - 1) + localItemsCount,
      of: listTotals17.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start17, limit], () => {
    refListTable17.value.refresh()
  })

  const dataList17 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listBeforeSalesOrderReturnDetailOfPurchase', {
          start: start17.value,
          limit: limit.value,
          order_by: orderBy17.value,
          order_desc: isSortDirDesc17.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals17.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '由采购单产生的前置销售单生成的退货单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*由采购单产生的销售单生成的退货单*/
  const refListTable18 = ref(null)
  // Table Handlers
  const tableColumns18 = [
    { key: 'returnboundId', label: '退货单Id', sortable: true },
    { key: 'returnboundNo', label: '退货单编号', sortable: true },
    { key: 'storeId', label: '店铺Id', sortable: true },
    { key: 'storeName', label: '店铺名称', sortable: true },
    { key: 'salesId', label: '销售员Id', sortable: true },
    { key: 'salesName', label: '销售员', sortable: true },
    { key: 'warehouseId', label: '入库仓库Id', sortable: true },
    { key: 'warehouseName', label: '入库仓库', sortable: true },
    { key: 'status', label: '入库状态', sortable: true },
    { key: 'returnTime', label: '退货日期', sortable: true },
    { key: 'returnboundTime', label: '退货入库时间', sortable: true },
    { key: 'returnboundCreator', label: '退货入库人', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'stockId', label: '库存Id', sortable: true },
    { key: 'salesreturnqty', label: '预计退货数量', sortable: true },
    { key: 'salesreturnqtyGood', label: '实际退货数量(良品)', sortable: true },
    { key: 'salesreturnqtyBad', label: '实际退货数量(不良品)', sortable: true },
    { key: 'realreturnQty', label: '实际退货数量', sortable: true },
    { key: 'productCostTax', label: '退货金额', sortable: true },
    { key: 'costPrice', label: '销售单价格', sortable: true },
    { key: 'isStatement', label: '是否已生成退货对账单', sortable: true },
    { key: 'preOrderId', label: '前置销售单Id', sortable: true },
    { key: 'preItemId', label: '前置销售明细Id', sortable: true },
  ]

  const start18 = ref(1)
  const limitOption18 = [1,2,3]
  const listTotals18 = ref(0)
  const orderBy18 = ref("returnboundId")
  const isSortDirDesc18 = ref(true)

  const dataMeta18 = computed(() => {
    const localItemsCount = refListTable18.value ? refListTable18.value.localItems.length : 0
    return {
      from: limit.value * (start18.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start18.value - 1) + localItemsCount,
      of: listTotals18.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start18, limit], () => {
    refListTable18.value.refresh()
  })

  const dataList18 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listSalesOrderReturnDetailOfPurchase', {
          start: start18.value,
          limit: limit.value,
          order_by: orderBy18.value,
          order_desc: isSortDirDesc18.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals18.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '由采购单产生的销售单生成的退货单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }
  /*采购入库单库存明细相关库存操作日志*/
  const refListTable19 = ref(null)
  // Table Handlers
  const tableColumns19 = [
    { key: 'logId', label: '库存日志Id', sortable: true },
    { key: 'warehouseName', label: '所在仓库', sortable: true },
    { key: 'stockId', label: '库存Id', sortable: true },
    { key: 'logType', label: '操作类型', sortable: true },
    { key: 'sourceQty', label: '变更前数量', sortable: true },
    { key: 'logQtySign', label: '本次变更数量', sortable: true },
    { key: 'afterQty', label: '变更后数量', sortable: true },
    { key: 'status', label: '库存状态', sortable: true },
    { key: 'productId', label: '商品Id', sortable: true },
    { key: 'name', label: '商品名称', sortable: true },
    { key: 'state', label: '是否回滚', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    { key: 'orderType', label: '关联订单分类', sortable: true },
    { key: 'relationalId', label: '关联订单Id', sortable: true },
    { key: 'relationalNo', label: '关联订单编号', sortable: true },
    { key: 'addTime', label: '发生时间', sortable: true },
    { key: 'creator', label: '操作人', sortable: true },
  ]

  const start19 = ref(1)
  const limitOption19 = [1,2,3]
  const listTotals19 = ref(0)
  const orderBy19 = ref()
  const isSortDirDesc19 = ref(true)

  const dataMeta19 = computed(() => {
    const localItemsCount = refListTable19.value ? refListTable19.value.localItems.length : 0
    return {
      from: limit.value * (start19.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start19.value - 1) + localItemsCount,
      of: listTotals19.value,
      timeLineShow: localItemsCount > 0 ? true : false,
    }
  })
  watch([start19, limit], () => {
    refListTable19.value.refresh()
  })

  const dataList19 = (ctx, callback) => {
    store
        .dispatch('purchaseordercycle/listPurchaseOrderStockDetailLog', {
          start: start19.value,
          limit: limit.value,
          order_by: orderBy19.value,
          order_desc: isSortDirDesc19.value === true ? 'desc':'',
          orderNo: orderNo.value,
        })
        .then(response => {
          const data = response.data
          if(data.code ===0){
            const list = data.data.list
            listTotals19.value = data.data.ext.totals
            callback(list)
          }
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购入库单库存明细相关库存操作日志列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }



  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {

    limitOptions,
    limit,
    refetchData,
    /*采购单*/
    dataList1,
    tableColumns1,
    start1,
    listTotals1: listTotals1,
    dataMeta1,
    orderBy1,
    isSortDirDesc1,
    refListTable1,
    limitOption1,

    /*采购入库单*/
    dataList2,
    tableColumns2,
    start2,
    listTotals2: listTotals2,
    dataMeta2,
    orderBy2,
    isSortDirDesc2,
    refListTable2,
    limitOption2,

    /*采购单付款单明细*/
    dataList3,
    tableColumns3,
    start3,
    listTotals3: listTotals3,
    dataMeta3,
    orderBy3,
    isSortDirDesc3,
    refListTable3,
    limitOption3,

    /*采购单的退货单明细*/
    dataList4,
    tableColumns4,
    start4,
    listTotals4: listTotals4,
    dataMeta4,
    orderBy4,
    isSortDirDesc4,
    refListTable4,
    limitOption4,

    /*采购单的退货单对应的前置退货单*/
    dataList5,
    tableColumns5,
    start5,
    listTotals5: listTotals5,
    dataMeta5,
    orderBy5,
    isSortDirDesc5,
    refListTable5,
    limitOption5,

    /*采购单的退货单对应的前置退货单*/
    dataList6,
    tableColumns6,
    start6,
    listTotals6: listTotals6,
    dataMeta6,
    orderBy6,
    isSortDirDesc6,
    refListTable6,
    limitOption6,

    /*采购单的退货单对应的前置退货单*/
    dataList7,
    tableColumns7,
    start7,
    listTotals7: listTotals7,
    dataMeta7,
    orderBy7,
    isSortDirDesc7,
    refListTable7,
    limitOption7,

    /*采购单的退货单对应的前置退货单*/
    dataList8,
    tableColumns8,
    start8,
    listTotals8: listTotals8,
    dataMeta8,
    orderBy8,
    isSortDirDesc8,
    refListTable8,
    limitOption8,

    /*采购单的退货单对应的前置退货单*/
    dataList9,
    tableColumns9,
    start9,
    listTotals9: listTotals9,
    dataMeta9,
    orderBy9,
    isSortDirDesc9,
    refListTable9,
    limitOption9,

    /*采购单的退货单对应的前置退货单*/
    dataList10,
    tableColumns10,
    start10,
    listTotals10: listTotals10,
    dataMeta10,
    orderBy10,
    isSortDirDesc10,
    refListTable10,
    limitOption10,

    /*采购单的退货单对应的前置退货单*/
    dataList11,
    tableColumns11,
    start11,
    listTotals11: listTotals11,
    dataMeta11,
    orderBy11,
    isSortDirDesc11,
    refListTable11,
    limitOption11,

    /*采购单的退货单对应的前置退货单*/
    dataList12,
    tableColumns12,
    start12,
    listTotals12: listTotals12,
    dataMeta12,
    orderBy12,
    isSortDirDesc12,
    refListTable12,
    limitOption12,

    /*采购单的退货单对应的前置退货单*/
    dataList13,
    tableColumns13,
    start13,
    listTotals13: listTotals13,
    dataMeta13,
    orderBy13,
    isSortDirDesc13,
    refListTable13,
    limitOption13,

    /*采购单的退货单对应的前置退货单*/
    dataList14,
    tableColumns14,
    start14,
    listTotals14: listTotals14,
    dataMeta14,
    orderBy14,
    isSortDirDesc14,
    refListTable14,
    limitOption14,

    /*采购单的退货单对应的前置退货单*/
    dataList15,
    tableColumns15,
    start15,
    listTotals15: listTotals15,
    dataMeta15,
    orderBy15,
    isSortDirDesc15,
    refListTable15,
    limitOption15,

    /*采购单的退货单对应的前置退货单*/
    dataList16,
    tableColumns16,
    start16,
    listTotals16: listTotals16,
    dataMeta16,
    orderBy16,
    isSortDirDesc16,
    refListTable16,
    limitOption16,

    /*采购单的退货单对应的前置退货单*/
    dataList17,
    tableColumns17,
    start17,
    listTotals17: listTotals17,
    dataMeta17,
    orderBy17,
    isSortDirDesc17,
    refListTable17,
    limitOption17,

    /*采购单的退货单对应的前置退货单*/
    dataList18,
    tableColumns18,
    start18,
    listTotals18: listTotals18,
    dataMeta18,
    orderBy18,
    isSortDirDesc18,
    refListTable18,
    limitOption18,

    /*采购单的退货单对应的前置退货单*/
    dataList19,
    tableColumns19,
    start19,
    listTotals19: listTotals19,
    dataMeta19,
    orderBy19,
    isSortDirDesc19,
    refListTable19,
    limitOption19,

    // 搜索条件
    orderNo,
  }
}
