import axios from '@axios'

export default {
  namespaced: true,
  getters: {
  },
  mutations: {
  },
  actions: {
    //采购单明细
    listPurchaseOrderDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listPurchaseOrderDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //采购入库单明细
    listPurchaseOrderInboundDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listPurchaseOrderInboundDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //采购单付款单明细
    listPurchaseOrderPaymentDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listPurchaseOrderPaymentDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //生成采购单的退货单明细
    listPurchaseOrderReturnDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listPurchaseOrderReturnDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //生成采购单的退货单对应的前置退货单
    listPurchaseOrderReturnBeforeOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listPurchaseOrderReturnBeforeOrder', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //采购入库单入库明细
    listPurchaseInboundDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listPurchaseInboundDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //采购入库单库存明细
    listPurchaseInboundStockDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listPurchaseInboundStockDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //由采购单生成的采购成本调价单
      listPurchaseOrderChangePriceDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listPurchaseOrderChangePriceDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //由采购单生成的采购退料单
    listPurchaseReturnDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listPurchaseReturnDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //采购入库单库存明细生成的箱规转换明细(作为转换前库存)
    listBeforeBoxChangeDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listBeforeBoxChangeDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //采购入库单库存明细生成的箱规转换明细(作为转换后库存)
    listAfterBoxChangeDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listAfterBoxChangeDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //采购入库单库存明细生成的库位转换明细
    listShelfChnageDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listShelfChnageDetail', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //由采购单产生的回滚单
    listRollbackOfPurchase(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listRollbackOfPurchase', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //由采购入库单库存产生的调拨单明细
    listRequisitionDetailOfPurchaseInbound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listRequisitionDetailOfPurchaseInbound', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //由采购单产生的销售单
    listSalesOrderOfPurchase(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listSalesOrderOfPurchase', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //由采购单产生的前置销售单
    listBeforeSalesOrderOfPurchase(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listBeforeSalesOrderOfPurchase', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //由采购单产生的前置销售单生成的退货单
    listBeforeSalesOrderReturnDetailOfPurchase(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listBeforeSalesOrderReturnDetailOfPurchase', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //由采购单产生的销售单生成的退货单
    listSalesOrderReturnDetailOfPurchase(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listSalesOrderReturnDetailOfPurchase', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //采购入库单库存明细相关库存操作日志
    listPurchaseOrderStockDetailLog(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/exportexcelfile/listPurchaseOrderStockDetailLog', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
