<template>

  <div>

    <b-card
        style="width:100%"
    >
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
        </b-row>
        <b-card>

          <b-form
              @submit.prevent="refreshTable"
              @reset.prevent="resetCondition"
          >

            <b-row>
              <!--  订单号  -->
              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单号:"
                    label-for="orderNo"
                    label-size="sm"
                    class="required"
                >
                  <b-form-input
                      id="orderNo"
                      v-model="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-button
                    variant="outline-primary"
                    type="submit"
                    class="mr-1"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </b-card>

    <b-row>
      <b-col
          cols="12"
          md="12"
      >
        <b-card title="采购方向">
          <app-timeline>

            <!-- 采购单明细 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta1.timeLineShow">无采购单明细</h6><h6 v-show="dataMeta1.timeLineShow">采购单明细</h6>
              </div>
              <b-table
                  id="table1"
                  v-show="dataMeta1.timeLineShow"
                  ref="refListTable1"
                  class="position-relative"
                  :items="dataList1"
                  responsive
                  :fields="tableColumns1"
                  primary-key="itemId"
                  :sort-by.sync="orderBy1"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc1"
              >

                <!-- Columns -->
                <template #cell(orderId)="data">
                  #{{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(supplierId)="data">
                  {{ data.item.supplier_id }}
                </template>
                <template #cell(supplierName)="data">
                  {{ data.item.supplier_name }}
                </template>
                <template #cell(contractId)="data">
                  {{ data.item.contract_id }}
                </template>
                <template #cell(contractNo)="data">
                  {{ data.item.contract_no }}
                </template>
                <template #cell(totalPaid)="data">
                  {{ data.item.total_paid }}
                </template>
                <template #cell(totalPay)="data">
                  {{ data.item.total_pay }}
                </template>
                <template #cell(totalQty)="data">
                  {{ data.item.total_qty }}
                </template>
                <template #cell(totalDeliveryQty)="data">
                  {{ data.item.total_delivery_qty }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('contract_status', data.item.status) }}
                </template>
                <template #cell(statusPay)="data">
                  {{ getCodeLabel('purchase_status_over', data.item.status_pay) }}
                </template>
                <template #cell(itemId)="data">
                  {{ data.item.item_id }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>
                <template #cell(productCostTax)="data">
                  {{ data.item.product_cost_tax }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta1.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta1.from }} - {{ dataMeta1.to }} / {{ dataMeta1.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start1"
                        :total-rows="listTotals1"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 采购入库单明细 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta2.timeLineShow">无采购入库单明细</h6><h6 v-show="dataMeta2.timeLineShow">采购入库单明细</h6>
              </div>
              <b-table
                  id="table2"
                  v-show="dataMeta2.timeLineShow"
                  ref="refListTable2"
                  class="position-relative"
                  :items="dataList2"
                  responsive
                  :fields="tableColumns2"
                  primary-key="inbounditem_id"
                  :sort-by.sync="orderBy2"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc2"
              >

                <!-- Columns -->
                <template #cell(orderId)="data">
                  #{{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(inboundId)="data">
                  {{ data.item.inbound_id }}
                </template>
                <template #cell(inboundNo)="data">
                  {{ data.item.inbound_no }}
                </template>
                <template #cell(warehouseId)="data">
                  {{ data.item.warehouse_id }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('purchase_inbound_status', data.item.status) }}
                </template>
                <template #cell(orderitemId)="data">
                  {{ data.item.orderitem_id }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(isGift)="data">
                  {{ getCodeLabel('yesno', data.item.is_gift) }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>
                <template #cell(trueqty)="data">
                  {{ data.item.trueqty }}
                </template>
                <template #cell(inventoryType)="data">
                  {{ getCodeLabel('inbound_inventory_type', data.item.inventory_type) }}
                </template>
                <template #cell(inboundTime)="data">
                  {{ toDate(data.item.inbound_time) }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta2.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta2.from }} - {{ dataMeta2.to }} / {{ dataMeta2.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start2"
                        :total-rows="listTotals2"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 采购单付款单明细 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta3.timeLineShow">无采购单付款单明细</h6><h6 v-show="dataMeta3.timeLineShow">采购单付款单明细</h6>
              </div>
              <b-table
                  id="table3"
                  v-show="dataMeta3.timeLineShow"
                  ref="refListTable3"
                  class="position-relative"
                  :items="dataList3"
                  responsive
                  :fields="tableColumns3"
                  primary-key="f_payment_item_id"
                  :sort-by.sync="orderBy3"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc3"
              >

                <!-- Columns -->
                <template #cell(fPaymentNo)="data">
                  #{{ data.item.f_payment_no }}
                </template>
                <template #cell(businessDate)="data">
                  {{ toDate(data.item.business_date) }}
                </template>
                <template #cell(fPaymentType)="data">
                  {{ getCodeLabel('f_payment_type', data.item.f_payment_type) }}
                </template>
                <template #cell(businessUnitId)="data">
                  {{ data.item.business_unit_id }}
                </template>
                <template #cell(businessUnitName)="data">
                  {{ data.item.business_unit_name }}
                </template>
                <template #cell(payeeId)="data">
                  {{ data.item.payee_id }}
                </template>
                <template #cell(payeeName)="data">
                  {{ data.item.payee_name }}
                </template>
                <template #cell(paymentOrgId)="data">
                  {{ data.item.payment_org_id }}
                </template>
                <template #cell(paymentOrgName)="data">
                  {{ data.item.payment_org_name }}
                </template>
                <template #cell(purchaseOrgId)="data">
                  {{ data.item.purchase_org_id }}
                </template>
                <template #cell(purchaseOrgName)="data">
                  {{ data.item.purchase_org_name }}
                </template>
                <template #cell(orderId)="data">
                  {{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(payableAmount)="data">
                  {{ data.item.payable_amount }}
                </template>
                <template #cell(paidAmount)="data">
                  {{ data.item.paid_amount }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('f_payment_status', data.item.status) }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta3.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta3.from }} - {{ dataMeta3.to }} / {{ dataMeta3.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start3"
                        :total-rows="listTotals3"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 采购单的退货单明细 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta4.timeLineShow">无采购单的退货单明细</h6><h6 v-show="dataMeta4.timeLineShow">采购单的退货单明细</h6>
              </div>
              <b-table
                  id="table4"
                  v-show="dataMeta4.timeLineShow"
                  ref="refListTable4"
                  class="position-relative"
                  :items="dataList4"
                  responsive
                  :fields="tableColumns4"
                  primary-key="returnbounditem_id"
                  :sort-by.sync="orderBy4"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc4"
              >

                <!-- Columns -->
                <template #cell(returnboundId)="data">
                  #{{ data.item.returnbound_id }}
                </template>
                <template #cell(returnboundNo)="data">
                  {{ data.item.returnbound_no }}
                </template>
                <template #cell(storeId)="data">
                  {{ data.item.store_id }}
                </template>
                <template #cell(storeName)="data">
                  {{ data.item.store_name }}
                </template>
                <template #cell(salesId)="data">
                  {{ data.item.sales_id }}
                </template>
                <template #cell(salesName)="data">
                  {{ data.item.sales_name }}
                </template>
                <template #cell(warehouseId)="data">
                  {{ data.item.warehouse_id }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('sales_returnbound_status', data.item.status) }}
                </template>
                <template #cell(returnTime)="data">
                  {{ toDate(data.item.return_time) }}
                </template>
                <template #cell(returnboundTime)="data">
                  {{ toDate(data.item.returnbound_time) }}
                </template>
                <template #cell(returnboundCreator)="data">
                  {{ getCodeLabel('user', data.item.returnbound_creator) }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(salesreturnqty)="data">
                  {{ data.item.salesreturnqty }}
                </template>
                <template #cell(salesreturnqtyGood)="data">
                  {{ data.item.salesreturnqty_good }}
                </template>
                <template #cell(salesreturnqtyBad)="data">
                  {{ data.item.salesreturnqty_bad }}
                </template>
                <template #cell(realreturnQty)="data">
                  {{ data.item.realreturn_qty }}
                </template>
                <template #cell(productCostTax)="data">
                  {{ data.item.product_cost_tax }}
                </template>
                <template #cell(costPrice)="data">
                  {{ data.item.cost_price }}
                </template>
                <template #cell(isStatement)="data">
                  {{ getCodeLabel('yesno', data.item.is_statement) }}
                </template>
                <template #cell(preOrderId)="data">
                  {{ data.item.pre_order_id }}
                </template>
                <template #cell(preItemId)="data">
                  {{ data.item.pre_item_id }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta4.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta4.from }} - {{ dataMeta4.to }} / {{ dataMeta4.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start4"
                        :total-rows="listTotals4"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 生成采购单的退货单对应的前置退货单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta5.timeLineShow">无采购单的退货单对应的前置退货单</h6><h6 v-show="dataMeta5.timeLineShow">采购单的退货单对应的前置退货单</h6>
              </div>
              <b-table
                  id="table5"
                  v-show="dataMeta5.timeLineShow"
                  ref="refListTable5"
                  class="position-relative"
                  :items="dataList5"
                  responsive
                  :fields="tableColumns5"
                  primary-key="itemId"
                  :sort-by.sync="orderBy5"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc5"
              >

                <!-- Columns -->
                <template #cell(returnboundId)="data">
                  #{{ data.item.returnbound_id }}
                </template>
                <template #cell(returnboundNo)="data">
                  {{ data.item.returnbound_no }}
                </template>
                <template #cell(channelId)="data">
                  {{ data.item.channel_id }}
                </template>
                <template #cell(channelName)="data">
                  {{ data.item.channel_name }}
                </template>
                <template #cell(warehouseId)="data">
                  {{ data.item.warehouse_id }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(storeId)="data">
                  {{ data.item.store_id }}
                </template>
                <template #cell(storeName)="data">
                  {{ data.item.store_name }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('pre_returnbound_status', data.item.status) }}
                </template>
                <template #cell(salesId)="data">
                  {{ data.item.sales_id }}
                </template>
                <template #cell(salesName)="data">
                  {{ data.item.sales_name }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(code)="data">
                  {{ data.item.code }}
                </template>
                <template #cell(returnPrice)="data">
                  {{ data.item.return_price }}
                </template>
                <template #cell(returnQty)="data">
                  {{ data.item.return_qty }}
                </template>
                <template #cell(createReturnboundId)="data">
                  {{ data.item.create_returnbound_id }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta5.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta5.from }} - {{ dataMeta5.to }} / {{ dataMeta5.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start5"
                        :total-rows="listTotals5"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 采购入库单入库明细 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta6.timeLineShow">无采购入库单入库明细</h6><h6 v-show="dataMeta6.timeLineShow">采购入库单入库明细</h6>
              </div>
              <b-table
                  id="table6"
                  v-show="dataMeta6.timeLineShow"
                  ref="refListTable6"
                  class="position-relative"
                  :items="dataList6"
                  responsive
                  :fields="tableColumns6"
                  primary-key="stockinitem_id"
                  :sort-by.sync="orderBy6"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc6"
              >

                <!-- Columns -->
                <template #cell(orderId)="data">
                  #{{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(warehouseId)="data">
                  {{ data.item.warehouse_id }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(inboundId)="data">
                  {{ data.item.inbound_id }}
                </template>
                <template #cell(inboundNo)="data">
                  {{ data.item.inbound_no }}
                </template>
                <template #cell(inbounditemId)="data">
                  {{ data.item.inbounditem_id }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>
                <template #cell(cost)="data">
                  {{ data.item.cost }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('sales_returnbound_status', data.item.status) }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(addTime)="data">
                  {{ toDate(data.item.add_time) }}
                </template>
                <template #cell(creator)="data">
                  {{ getCodeLabel('user', data.item.creator) }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta6.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta6.from }} - {{ dataMeta6.to }} / {{ dataMeta6.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start6"
                        :total-rows="listTotals6"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 采购入库单库存明细 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta7.timeLineShow">无采购入库单库存明细</h6><h6 v-show="dataMeta7.timeLineShow">采购入库单库存明细</h6>
              </div>
              <b-table
                  id="table7"
                  v-show="dataMeta7.timeLineShow"
                  ref="refListTable7"
                  class="position-relative"
                  :items="dataList7"
                  responsive
                  :fields="tableColumns7"
                  primary-key="stock_id"
                  :sort-by.sync="orderBy7"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc7"
              >

                <!-- Columns -->
                <template #cell(stockId)="data">
                  #{{ data.item.stock_id }}
                </template>
                <template #cell(warehouseId)="data">
                  {{ data.item.warehouse_id }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(isGift)="data">
                  {{ getCodeLabel('yesno', data.item.is_gift) }}
                </template>
                <template #cell(purchaseorderId)="data">
                  {{ data.item.purchaseorder_id }}
                </template>
                <template #cell(purchaseorderItemid)="data">
                  {{ data.item.purchaseorder_ItemId }}
                </template>
                <template #cell(cost)="data">
                  {{ data.item.cost }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('stock_status', data.item.status) }}
                </template>
                <template #cell(isVmi)="data">
                  {{ getCodeLabel('yesno', data.item.is_vmi) }}
                </template>
                <template #cell(modifyTime)="data">
                  {{ toDate(data.item.modify_time) }}
                </template>
                <template #cell(updator)="data">
                  {{ getCodeLabel('user', data.item.updator) }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta7.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta7.from }} - {{ dataMeta7.to }} / {{ dataMeta7.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start7"
                        :total-rows="listTotals7"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 由采购单生成的采购成本调价单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta8.timeLineShow">无由采购单生成的采购成本调价单</h6><h6 v-show="dataMeta8.timeLineShow">由采购单生成的采购成本调价单</h6>
              </div>
              <b-table
                  id="table8"
                  v-show="dataMeta8.timeLineShow"
                  ref="refListTable8"
                  class="position-relative"
                  :items="dataList8"
                  responsive
                  :fields="tableColumns8"
                  primary-key="change_id"
                  :sort-by.sync="orderBy8"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc8"
              >

                <!-- Columns -->
                <template #cell(changeId)="data">
                  #{{ data.item.change_id }}
                </template>
                <template #cell(changeNo)="data">
                  {{ data.item.change_no }}
                </template>
                <template #cell(orderId)="data">
                  {{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(itemId)="data">
                  {{ data.item.item_id }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(productCostTaxOld)="data">
                  {{ data.item.product_cost_tax_old }}
                </template>
                <template #cell(productCostTaxNew)="data">
                  {{ data.item.product_cost_tax_new }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>
                <template #cell(memo)="data">
                  {{ data.item.memo }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('purchase_order_changecost_status', data.item.status) }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta8.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta8.from }} - {{ dataMeta8.to }} / {{ dataMeta8.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start8"
                        :total-rows="listTotals8"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 由采购单生成的采购退料单  -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta9.timeLineShow">无由采购单生成的采购退料单</h6><h6 v-show="dataMeta9.timeLineShow">由采购单生成的采购退料单</h6>
              </div>
              <b-table
                  id="table9"
                  v-show="dataMeta9.timeLineShow"
                  ref="refListTable9"
                  class="position-relative"
                  :items="dataList9"
                  responsive
                  :fields="tableColumns9"
                  primary-key="outbounditem_id"
                  :sort-by.sync="orderBy9"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc9"
              >

                <!-- Columns -->
                <template #cell(outboundId)="data">
                  #{{ data.item.outbound_id }}
                </template>
                <template #cell(outboundNo)="data">
                  {{ data.item.outbound_no }}
                </template>
                <template #cell(returnboundId)="data">
                  {{ data.item.returnbound_id }}
                </template>
                <template #cell(returnboundNo)="data">
                  {{ data.item.returnbound_no }}
                </template>
                <template #cell(orderId)="data">
                  {{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(warehouseId)="data">
                  {{ data.item.warehouse_id }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(reason)="data">
                  {{ data.item.reason }}
                </template>
                <template #cell(returnTime)="data">
                  {{ toDate(data.item.return_time) }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('purchase_outbound_status', data.item.status) }}
                </template>
                <template #cell(inboundId)="data">
                  {{ data.item.inbound_id }}
                </template>
                <template #cell(inboundNo)="data">
                  {{ data.item.inbound_no }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(warestatus)="data">
                  {{ data.item.warestatus }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>
                <template #cell(trueqty)="data">
                  {{ data.item.trueqty }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta9.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta9.from }} - {{ dataMeta9.to }} / {{ dataMeta9.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start9"
                        :total-rows="listTotals9"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 采购入库单库存明细生成的箱规转换明细(作为转换前库存) -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta10.timeLineShow">无采购入库单库存明细生成的箱规转换明细(作为转换前库存)</h6><h6 v-show="dataMeta10.timeLineShow">采购入库单库存明细生成的箱规转换明细(作为转换前库存)</h6>
              </div>
              <b-table
                  id="table10"
                  v-show="dataMeta10.timeLineShow"
                  ref="refListTable10"
                  class="position-relative"
                  :items="dataList10"
                  responsive
                  :fields="tableColumns10"
                  primary-key="change_id"
                  :sort-by.sync="orderBy10"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc10"
              >

                <!-- Columns -->
                <template #cell(changeNo)="data">
                  #{{ data.item.change_no }}
                </template>
                <template #cell(applierName)="data">
                  {{ data.item.applier_name }}
                </template>
                <template #cell(type)="data">
                  {{ getCodeLabel('stockchange_type', data.item.type) }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(sourceProductId)="data">
                  {{ data.item.source_product_id }}
                </template>
                <template #cell(sourceName)="data">
                  {{ data.item.source_name }}
                </template>
                <template #cell(sourceCode)="data">
                  {{ data.item.source_code }}
                </template>
                <template #cell(sourceBoxQuantity)="data">
                  {{ data.item.source_box_quantity }}
                </template>
                <template #cell(sourceStockId)="data">
                  {{ data.item.source_stock_id }}
                </template>
                <template #cell(sourceCost)="data">
                  {{ data.item.source_cost }}
                </template>
                <template #cell(sourceBeforeqty)="data">
                  {{ data.item.source_beforeqty }}
                </template>
                <template #cell(convertProductId)="data">
                  {{ data.item.convert_product_id }}
                </template>
                <template #cell(convertName)="data">
                  {{ data.item.convert_name }}
                </template>
                <template #cell(convertCode)="data">
                  {{ data.item.convert_code }}
                </template>
                <template #cell(convertBoxQuantity)="data">
                  {{ data.item.convert_box_quantity }}
                </template>
                <template #cell(convertStockId)="data">
                  {{ data.item.convert_stock_id }}
                </template>
                <template #cell(convertCost)="data">
                  {{ data.item.convert_cost }}
                </template>
                <template #cell(convertBeforeqty)="data">
                  {{ data.item.convert_beforeqty }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('stockchange_status', data.item.status) }}
                </template>
                <template #cell(state)="data">
                  {{ getCodeLabel('yesno', data.item.state) }}
                </template>
                <template #cell(createTime)="data">
                  {{toDate(data.item.create_time)}}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta10.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta10.from }} - {{ dataMeta10.to }} / {{ dataMeta10.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start10"
                        :total-rows="listTotals10"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 采购入库单库存明细生成的箱规转换明细(作为转换后库存) -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta11.timeLineShow">无采购入库单库存明细生成的箱规转换明细(作为转换后库存)</h6><h6 v-show="dataMeta11.timeLineShow">采购入库单库存明细生成的箱规转换明细(作为转换后库存)</h6>
              </div>
              <b-table
                  id="table11"
                  v-show="dataMeta11.timeLineShow"
                  ref="refListTable11"
                  class="position-relative"
                  :items="dataList11"
                  responsive
                  :fields="tableColumns11"
                  primary-key="change_id"
                  :sort-by.sync="orderBy11"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc11"
              >

                <!-- Columns -->
                <template #cell(changeNo)="data">
                  #{{ data.item.change_no }}
                </template>
                <template #cell(applierName)="data">
                  {{ data.item.applier_name }}
                </template>
                <template #cell(type)="data">
                  {{ getCodeLabel('stockchange_type', data.item.status) }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(sourceProductId)="data">
                  {{ data.item.source_product_id }}
                </template>
                <template #cell(sourceName)="data">
                  {{ data.item.source_name }}
                </template>
                <template #cell(sourceCode)="data">
                  {{ data.item.source_code }}
                </template>
                <template #cell(sourceBoxQuantity)="data">
                  {{ data.item.source_box_quantity }}
                </template>
                <template #cell(sourceStockId)="data">
                  {{ data.item.source_stock_id }}
                </template>
                <template #cell(sourceCost)="data">
                  {{ data.item.source_cost }}
                </template>
                <template #cell(sourceBeforeqty)="data">
                  {{ data.item.source_beforeqty }}
                </template>
                <template #cell(convertProductId)="data">
                  {{ data.item.convert_product_id }}
                </template>
                <template #cell(convertName)="data">
                  {{ data.item.convert_name }}
                </template>
                <template #cell(convertCode)="data">
                  {{ data.item.convert_code }}
                </template>
                <template #cell(convertBoxQuantity)="data">
                  {{ data.item.convert_box_quantity }}
                </template>
                <template #cell(convertStockId)="data">
                  {{ data.item.convert_stock_id }}
                </template>
                <template #cell(convertCost)="data">
                  {{ data.item.convert_cost }}
                </template>
                <template #cell(convertBeforeqty)="data">
                  {{ data.item.convert_beforeqty }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('stockchange_status', data.item.status) }}
                </template>
                <template #cell(state)="data">
                  {{ getCodeLabel('yesno', data.item.state) }}
                </template>
                <template #cell(createTime)="data">
                  {{toDate(data.item.create_time)}}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta11.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta11.from }} - {{ dataMeta11.to }} / {{ dataMeta11.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start11"
                        :total-rows="listTotals11"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 采购入库单库存明细生成的库位转换明细 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta12.timeLineShow">无采购入库单库存明细生成的库位转换明细</h6><h6 v-show="dataMeta12.timeLineShow">采购入库单库存明细生成的库位转换明细</h6>
              </div>
              <b-table
                  id="table12"
                  v-show="dataMeta12.timeLineShow"
                  ref="refListTable12"
                  class="position-relative"
                  :items="dataList12"
                  responsive
                  :fields="tableColumns12"
                  primary-key="change_id"
                  :sort-by.sync="orderBy12"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc12"
              >

                <!-- Columns -->
                <template #cell(changeNo)="data">
                  #{{ data.item.change_no }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(stockQty)="data">
                  {{ data.item.stock_qty }}
                </template>
                <template #cell(stockLocation)="data">
                  {{ data.item.stock_location }}
                </template>
                <template #cell(goodstock)="data">
                  {{ data.item.goodstock }}
                </template>
                <template #cell(goodlocation)="data">
                  {{ data.item.goodlocation }}
                </template>
                <template #cell(badstock)="data">
                  {{ data.item.badstock }}
                </template>
                <template #cell(badlocation)="data">
                  {{ data.item.badlocation }}
                </template>
                <template #cell(reason)="data">
                  {{ data.item.reason }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('stock_status_change', data.item.status) }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta12.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta12.from }} - {{ dataMeta12.to }} / {{ dataMeta12.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start12"
                        :total-rows="listTotals12"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 由采购单产生的回滚单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta13.timeLineShow">无由采购单产生的回滚单</h6><h6 v-show="dataMeta13.timeLineShow">由采购单产生的回滚单</h6>
              </div>
              <b-table
                  id="table13"
                  v-show="dataMeta13.timeLineShow"
                  ref="refListTable13"
                  class="position-relative"
                  :items="dataList13"
                  responsive
                  :fields="tableColumns13"
                  primary-key="rollback_id"
                  :sort-by.sync="orderBy13"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc13"
              >

                <!-- Columns -->
                <template #cell(rollbackNo)="data">
                  #{{ data.item.rollback_no }}
                </template>
                <template #cell(relationalId)="data">
                  {{ data.item.relational_id }}
                </template>
                <template #cell(relationalNo)="data">
                  {{ data.item.relational_no }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('stock_rollback_status', data.item.status) }}
                </template>
                <template #cell(createTime)="data">
                  {{ toDate(data.item.create_time) }}
                </template>
                <template #cell(creator)="data">
                  {{ getCodeLabel('user', data.item.creator) }}
                </template>
                <template #cell(approveTime)="data">
                  {{ toDate(data.item.approve_time) }}
                </template>
                <template #cell(approver)="data">
                  {{ getCodeLabel('user', data.item.approver) }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta13.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta13.from }} - {{ dataMeta13.to }} / {{ dataMeta13.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start13"
                        :total-rows="listTotals13"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 由采购入库单库存产生的调拨单明细 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta14.timeLineShow">无由采购入库单库存产生的调拨单明细</h6><h6 v-show="dataMeta14.timeLineShow">由采购入库单库存产生的调拨单明细</h6>
              </div>
              <b-table
                  id="table14"
                  v-show="dataMeta14.timeLineShow"
                  ref="refListTable14"
                  class="position-relative"
                  :items="dataList14"
                  responsive
                  :fields="tableColumns14"
                  primary-key="outbounditem_id"
                  :sort-by.sync="orderBy14"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc14"
              >

                <!-- Columns -->
                <template #cell(outboundId)="data">
                  #{{ data.item.outbound_id }}
                </template>
                <template #cell(outboundNo)="data">
                  {{ data.item.outbound_no }}
                </template>
                <template #cell(fromwarehouseId)="data">
                  {{ data.item.fromwarehouse_id }}
                </template>
                <template #cell(fromwarehouseName)="data">
                  {{ data.item.fromwarehouse_name }}
                </template>
                <template #cell(towarehouseId)="data">
                  {{ data.item.towarehouse_id }}
                </template>
                <template #cell(towarehouseName)="data">
                  {{ data.item.towarehouse_name }}
                </template>
                <template #cell(outbounditemId)="data">
                  {{ data.item.outbounditem_id }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(warestatus)="data">
                  {{ data.item.warestatus }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>
                <template #cell(trueqty)="data">
                  {{ data.item.trueqty }}
                </template>
                <template #cell(receiveqty)="data">
                  {{ data.item.receiveqty }}
                </template>
                <template #cell(mainStatus)="data">
                  {{ getCodeLabel('allot_status', data.item.main_status) }}
                </template>
                <template #cell(subStatus)="data">
                  {{ getCodeLabel('allot_outbound_status', data.item.sub_status) }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta14.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta14.from }} - {{ dataMeta14.to }} / {{ dataMeta14.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
<!--                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start14"
                        :total-rows="listTotals14"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>-->
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 由采购单产生的销售单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta15.timeLineShow">无由采购单产生的销售单</h6><h6 v-show="dataMeta15.timeLineShow">由采购单产生的销售单</h6>
              </div>
              <b-table
                  id="table15"
                  v-show="dataMeta15.timeLineShow"
                  ref="refListTable15"
                  class="position-relative"
                  :items="dataList15"
                  responsive
                  :fields="tableColumns15"
                  primary-key="item_id"
                  :sort-by.sync="orderBy15"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc15"
              >

                <!-- Columns -->
                <template #cell(orderId)="data">
                  #{{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(typeId)="data">
                  {{ getCodeLabel('business_type', data.item.type_id) }}
                </template>
                <template #cell(channelId)="data">
                  {{ data.item.channel_id }}
                </template>
                <template #cell(channelName)="data">
                  {{ data.item.channel_name }}
                </template>
                <template #cell(salesTime)="data">
                  {{ data.item.sales_time }}
                </template>
                <template #cell(salesId)="data">
                  {{ data.item.sales_id }}
                </template>
                <template #cell(salesName)="data">
                  {{ data.item.sales_name }}
                </template>
                <template #cell(storeId)="data">
                  {{ data.item.store_id }}
                </template>
                <template #cell(storeName)="data">
                  {{ data.item.store_name }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('contract_status', data.item.status) }}
                </template>
                <template #cell(salesWarehouse)="data">
                  {{ data.item.sales_warehouse }}
                </template>
                <template #cell(isStatement)="data">
                  {{getCodeLabel('yesno', data.item.is_statement)}}
                </template>
                <template #cell(isInvoice)="data">
                  {{getCodeLabel('yesno', data.item.is_invoice)}}
                </template>
                <template #cell(itemId)="data">
                  {{ data.item.item_id }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(isGift)="data">
                  {{getCodeLabel('yesno', data.item.is_gift)}}
                </template>
                <template #cell(costPrice)="data">
                  {{ data.item.cost_price }}
                </template>
                <template #cell(productCostTax)="data">
                  {{ data.item.product_cost_tax }}
                </template>
                <template #cell(taxRate)="data">
                  {{ data.item.tax_rate }}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty }}
                </template>
                <template #cell(subtotal)="data">
                  {{ data.item.subtotal }}
                </template>
                <template #cell(preOrderId)="data">
                  {{ data.item.pre_order_id }}
                </template>
                <template #cell(preItemId)="data">
                  {{ data.item.pre_item_id }}
                </template>
                <template #cell(isSendbi)="data">
                  {{getCodeLabel('yesno', data.item.is_sendbi)}}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta15.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta15.from }} - {{ dataMeta15.to }} / {{ dataMeta15.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start15"
                        :total-rows="listTotals15"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 由采购单产生的前置销售单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta16.timeLineShow">无由采购单产生的前置销售单</h6><h6 v-show="dataMeta16.timeLineShow">由采购单产生的前置销售单</h6>
              </div>
              <b-table
                  id="table16"
                  v-show="dataMeta16.timeLineShow"
                  ref="refListTable16"
                  class="position-relative"
                  :items="dataList16"
                  responsive
                  :fields="tableColumns16"
                  primary-key="itemId"
                  :sort-by.sync="orderBy16"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc16"
              >

                <!-- Columns -->
                <template #cell(orderId)="data">
                  #{{ data.item.order_id }}
                </template>
                <template #cell(orderNo)="data">
                  {{ data.item.order_no }}
                </template>
                <template #cell(typeId)="data">
                  {{ data.item.type_id }}
                </template>
                <template #cell(source)="data">
                  {{ data.item.source }}
                </template>
                <template #cell(channelId)="data">
                  {{ data.item.channel_id }}
                </template>
                <template #cell(channelName)="data">
                  {{ data.item.channel_name }}
                </template>
                <template #cell(storeId)="data">
                  {{ data.item.store_id }}
                </template>
                <template #cell(storeName)="data">
                  {{ data.item.store_name }}
                </template>
                <template #cell(salesTime)="data">
                  {{ toDate(data.item.sales_time) }}
                </template>
                <template #cell(salesId)="data">
                  {{ data.item.sales_id }}
                </template>
                <template #cell(salesName)="data">
                  {{ data.item.sales_name }}
                </template>
                <template #cell(addTime)="data">
                  {{ toDate(data.item.add_time) }}
                </template>
                <template #cell(isReceipt)="data">
                  {{ getCodeLabel('yesno', data.item.is_receipt) }}
                </template>
                <template #cell(receiptTime)="data">
                  {{ toDate(data.item.receipt_time) }}
                </template>
                <template #cell(isCheckout)="data">
                  {{ getCodeLabel('yesno', data.item.is_checkout) }}
                </template>
                <template #cell(checkTime)="data">
                  {{ toDate(data.item.check_time) }}
                </template>
                <template #cell(isInvoice)="data">
                  {{ getCodeLabel('yesno', data.item.is_invoice) }}
                </template>
                <template #cell(salesWarehouse)="data">
                  {{ data.item.sales_warehouse }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('pre_order_status', data.item.status) }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(salesPrice)="data">
                  {{ data.item.sales_price }}
                </template>
                <template #cell(salesQty)="data">
                  {{ data.item.sales_qty }}
                </template>
                <template #cell(refuseQty)="data">
                  {{ data.item.refuse_qty }}
                </template>
                <template #cell(createOrderId)="data">
                  {{ data.item.create_order_id }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta16.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta16.from }} - {{ dataMeta16.to }} / {{ dataMeta16.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start16"
                        :total-rows="listTotals16"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 由采购单产生的前置销售单生成的退货单 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta17.timeLineShow">无由采购单产生的前置销售单生成的退货单</h6><h6 v-show="dataMeta17.timeLineShow">由采购单产生的前置销售单生成的退货单</h6>
              </div>
              <b-table
                  id="table17"
                  v-show="dataMeta17.timeLineShow"
                  ref="refListTable17"
                  class="position-relative"
                  :items="dataList17"
                  responsive
                  :fields="tableColumns17"
                  primary-key="returnbounditemId"
                  :sort-by.sync="orderBy17"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc17"
              >

                <!-- Columns -->
                <template #cell(returnboundId)="data">
                  #{{ data.item.returnbound_id }}
                </template>
                <template #cell(returnboundNo)="data">
                  {{ data.item.returnbound_no }}
                </template>
                <template #cell(storeId)="data">
                  {{ data.item.store_id }}
                </template>
                <template #cell(storeName)="data">
                  {{ data.item.store_name }}
                </template>
                <template #cell(salesId)="data">
                  {{ data.item.sales_id }}
                </template>
                <template #cell(salesName)="data">
                  {{ data.item.sales_name }}
                </template>
                <template #cell(warehouseId)="data">
                  {{ data.item.warehouse_id }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('sales_returnbound_status', data.item.status) }}
                </template>
                <template #cell(returnTime)="data">
                  {{ toDate(data.item.return_time) }}
                </template>
                <template #cell(returnboundTime)="data">
                  {{ toDate(data.item.returnbound_time) }}
                </template>
                <template #cell(returnboundCreator)="data">
                  {{ getCodeLabel('user', data.item.returnbound_creator) }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(salesreturnqty)="data">
                  {{ data.item.salesreturnqty }}
                </template>
                <template #cell(salesreturnqtyGood)="data">
                  {{ data.item.salesreturnqty_good }}
                </template>
                <template #cell(salesreturnqtyBad)="data">
                  {{ data.item.salesreturnqty_bad }}
                </template>
                <template #cell(RealreturnQty)="data">
                  {{ data.item.realreturn_qty }}
                </template>
                <template #cell(productCostTax)="data">
                  {{ data.item.product_cost_tax }}
                </template>
                <template #cell(costPrice)="data">
                  {{ data.item.cost_price }}
                </template>
                <template #cell(isStatement)="data">
                  {{ getCodeLabel('yesno', data.item.is_statement) }}
                </template>
                <template #cell(preOrderId)="data">
                  {{ data.item.pre_order_id }}
                </template>
                <template #cell(preItemId)="data">
                  {{ data.item.pre_item_id }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta17.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta17.from }} - {{ dataMeta17.to }} / {{ dataMeta17.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start17"
                        :total-rows="listTotals17"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 由采购单产生的销售单生成的退货单  -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta18.timeLineShow">无由采购单产生的销售单生成的退货单</h6><h6 v-show="dataMeta18.timeLineShow">由采购单产生的销售单生成的退货单</h6>
              </div>
              <b-table
                  id="table18"
                  v-show="dataMeta18.timeLineShow"
                  ref="refListTable18"
                  class="position-relative"
                  :items="dataList18"
                  responsive
                  :fields="tableColumns18"
                  primary-key="returnbounditemId"
                  :sort-by.sync="orderBy18"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc18"
              >

                <!-- Columns -->
                <template #cell(returnboundId)="data">
                  #{{ data.item.returnbound_id }}
                </template>
                <template #cell(returnboundNo)="data">
                  {{ data.item.returnbound_no }}
                </template>
                <template #cell(storeId)="data">
                  {{ data.item.store_id }}
                </template>
                <template #cell(storeName)="data">
                  {{ data.item.store_name }}
                </template>
                <template #cell(salesId)="data">
                  {{ data.item.sales_id }}
                </template>
                <template #cell(salesName)="data">
                  {{ data.item.sales_name }}
                </template>
                <template #cell(warehouseId)="data">
                  {{ data.item.warehouse_id }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('sales_returnbound_status', data.item.status) }}
                </template>
                <template #cell(returnTime)="data">
                  {{ toDate(data.item.return_time) }}
                </template>
                <template #cell(returnboundTime)="data">
                  {{ toDate(data.item.returnbound_time) }}
                </template>
                <template #cell(returnboundCreator)="data">
                  {{ getCodeLabel('user', data.item.returnbound_creator) }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(salesreturnqty)="data">
                  {{ data.item.salesreturnqty }}
                </template>
                <template #cell(salesreturnqtyGood)="data">
                  {{ data.item.salesreturnqty_good }}
                </template>
                <template #cell(salesreturnqtyBad)="data">
                  {{ data.item.salesreturnqty_bad }}
                </template>
                <template #cell(realreturnQty)="data">
                  {{ data.item.realreturn_qty }}
                </template>
                <template #cell(productCostTax)="data">
                  {{ data.item.product_cost_tax }}
                </template>
                <template #cell(costPrice)="data">
                  {{ data.item.cost_price }}
                </template>
                <template #cell(isStatement)="data">
                  {{ getCodeLabel('yesno', data.item.is_statement) }}
                </template>
                <template #cell(preOrderId)="data">
                  {{ data.item.pre_order_id }}
                </template>
                <template #cell(preItemId)="data">
                  {{ data.item.pre_item_id }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta18.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta18.from }} - {{ dataMeta18.to }} / {{ dataMeta18.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                  <b-pagination
                      v-model="start18"
                      :total-rows="listTotals18"
                      :per-page="limit"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            <!-- 采购入库单库存明细相关库存操作日志 -->
            <app-timeline-item
                variant="success"
                icon="FileTextIcon"
            >

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6 v-show="!dataMeta19.timeLineShow">无采购入库单库存明细相关库存操作日志</h6><h6 v-show="dataMeta19.timeLineShow">采购入库单库存明细相关库存操作日志</h6>
              </div>
              <b-table
                  id="table19"
                  v-show="dataMeta19.timeLineShow"
                  ref="refListTable19"
                  class="position-relative"
                  :items="dataList19"
                  responsive
                  :fields="tableColumns19"
                  primary-key="log_id"
                  :sort-by.sync="orderBy19"
                  show-empty
                  empty-text="未找到记录"
                  :sort-desc.sync="isSortDirDesc19"
              >

                <!-- Columns -->
                <template #cell(logId)="data">
                  #{{ data.item.log_id }}
                </template>
                <template #cell(warehouseName)="data">
                  {{ data.item.warehouse_name }}
                </template>
                <template #cell(stockId)="data">
                  {{ data.item.stock_id }}
                </template>
                <template #cell(logType)="data">
                  {{ getCodeLabel('stock_log_type', data.item.log_type) }}
                </template>
                <template #cell(sourceQty)="data">
                  {{ data.item.source_qty }}
                </template>
                <template #cell(logQtySign)="data">
                  {{ data.item.log_qty_sign }}
                </template>
                <template #cell(afterQty)="data">
                  {{ data.item.after_qty }}
                </template>
                <template #cell(status)="data">
                  {{ getCodeLabel('sales_returnbound_status', data.item.status) }}
                </template>
                <template #cell(productId)="data">
                  {{ data.item.product_id }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(state)="data">
                  {{ getCodeLabel('yesno', data.item.state) }}
                </template>
                <template #cell(remark)="data">
                  {{ data.item.remark }}
                </template>
                <template #cell(orderType)="data">
                  {{ getCodeLabel('stock_log_order_type', data.item.order_type) }}
                </template>
                <template #cell(relationalId)="data">
                  {{ data.item.relational_id }}
                </template>
                <template #cell(relationalNo)="data">
                  {{ data.item.relational_no }}
                </template>
                <template #cell(addTime)="data">
                  {{ toDate(data.item.add_time) }}
                </template>
                <template #cell(creator)="data">
                  {{ getCodeLabel('user', data.item.creator) }}
                </template>

              </b-table>
              <div class="mx-2 mb-2" v-show="dataMeta19.timeLineShow">
                <b-row>

                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta19.from }} - {{ dataMeta19.to }} / {{ dataMeta19.of }} 记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                        v-model="start19"
                        :total-rows="listTotals19"
                        :per-page="limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-timeline-item>
            
          </app-timeline>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BRow, BCol, BCard,
  BImg, BAvatar, BMedia,BTable, BButton, BCollapse, VBToggle, BListGroup, BListGroupItem, BAvatarGroup, BBadge, VBTooltip,} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import store from "@/store";
import purchaseordercycleStore from "@/views/apps/purchaseordercycle/purchaseordercycleStore";
import {onUnmounted} from "@vue/composition-api";
import purchaseOrderCycleDetailList from "@/views/apps/purchaseordercycle/purchaseOrderCycleDetailList";
import {avatarText, toDate,getCode, getCodeColor, getCodeLabel, getCodeOptions} from "@core/utils/filter";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BAvatar,
    BMedia,
    BTable,
    BButton,
    BCollapse,
    VBToggle,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    VBTooltip,
    AppTimeline,
    AppTimelineItem,
  },
  setup() {

    // Register module
    if (!store.hasModule('purchaseordercycle')) store.registerModule('purchaseordercycle', purchaseordercycleStore)

    onUnmounted(() => {
      if (store.hasModule('purchaseordercycle')) store.unregisterModule('purchaseordercycle')
    })


    const {
      refetchData,
      orderNo,
      limitOptions,
      limit,

      dataList1,
      tableColumns1,
      start1,
      listTotals1,
      dataMeta1,
      orderBy1,
      isSortDirDesc1,
      refListTable1,

      dataList2,
      tableColumns2,
      start2,
      listTotals2,
      dataMeta2,
      orderBy2,
      isSortDirDesc2,
      refListTable2,

      dataList3,
      tableColumns3,
      start3,
      listTotals3,
      dataMeta3,
      orderBy3,
      isSortDirDesc3,
      refListTable3,

      dataList4,
      tableColumns4,
      start4,
      listTotals4,
      dataMeta4,
      orderBy4,
      isSortDirDesc4,
      refListTable4,

      dataList5,
      tableColumns5,
      start5,
      listTotals5,
      dataMeta5,
      orderBy5,
      isSortDirDesc5,
      refListTable5,

      dataList6,
      tableColumns6,
      start6,
      listTotals6,
      dataMeta6,
      orderBy6,
      isSortDirDesc6,
      refListTable6,

      dataList7,
      tableColumns7,
      start7,
      listTotals7,
      dataMeta7,
      orderBy7,
      isSortDirDesc7,
      refListTable7,

      dataList8,
      tableColumns8,
      start8,
      listTotals8,
      dataMeta8,
      orderBy8,
      isSortDirDesc8,
      refListTable8,

      dataList9,
      tableColumns9,
      start9,
      listTotals9,
      dataMeta9,
      orderBy9,
      isSortDirDesc9,
      refListTable9,

      dataList10,
      tableColumns10,
      start10,
      listTotals10,
      dataMeta10,
      orderBy10,
      isSortDirDesc10,
      refListTable10,

      dataList11,
      tableColumns11,
      start11,
      listTotals11,
      dataMeta11,
      orderBy11,
      isSortDirDesc11,
      refListTable11,

      dataList12,
      tableColumns12,
      start12,
      listTotals12,
      dataMeta12,
      orderBy12,
      isSortDirDesc12,
      refListTable12,

      dataList13,
      tableColumns13,
      start13,
      listTotals13,
      dataMeta13,
      orderBy13,
      isSortDirDesc13,
      refListTable13,

      dataList14,
      tableColumns14,
      start14,
      listTotals14,
      dataMeta14,
      orderBy14,
      isSortDirDesc14,
      refListTable14,

      dataList15,
      tableColumns15,
      start15,
      listTotals15,
      dataMeta15,
      orderBy15,
      isSortDirDesc15,
      refListTable15,

      dataList16,
      tableColumns16,
      start16,
      listTotals16,
      dataMeta16,
      orderBy16,
      isSortDirDesc16,
      refListTable16,

      dataList17,
      tableColumns17,
      start17,
      listTotals17,
      dataMeta17,
      orderBy17,
      isSortDirDesc17,
      refListTable17,

      dataList18,
      tableColumns18,
      start18,
      listTotals18,
      dataMeta18,
      orderBy18,
      isSortDirDesc18,
      refListTable18,

      dataList19,
      tableColumns19,
      start19,
      listTotals19,
      dataMeta19,
      orderBy19,
      isSortDirDesc19,
      refListTable19,

    } = purchaseOrderCycleDetailList();
    const methods = {

      refreshTable  (){
        this.refetchData()
      },

      resetCondition() {
        this.orderNo = ""
        this.refetchData()
      },
    }
    return {
      ...methods,
      BImg,
      BAvatar,
      BMedia,
      BTable,
      BButton,
      BCollapse,
      VBToggle,
      BListGroup,
      BListGroupItem,
      BAvatarGroup,
      BBadge,
      VBTooltip,
      AppTimeline,
      AppTimelineItem,

      refetchData,
      orderNo,
      limitOptions,
      limit,

      dataList1,
      tableColumns1,
      start1,
      listTotals1,
      dataMeta1,
      orderBy1,
      isSortDirDesc1,
      refListTable1,

      dataList2,
      tableColumns2,
      start2,
      listTotals2,
      dataMeta2,
      orderBy2,
      isSortDirDesc2,
      refListTable2,

      dataList3,
      tableColumns3,
      start3,
      listTotals3,
      dataMeta3,
      orderBy3,
      isSortDirDesc3,
      refListTable3,

      dataList4,
      tableColumns4,
      start4,
      listTotals4,
      dataMeta4,
      orderBy4,
      isSortDirDesc4,
      refListTable4,

      dataList5,
      tableColumns5,
      start5,
      listTotals5,
      dataMeta5,
      orderBy5,
      isSortDirDesc5,
      refListTable5,

      dataList6,
      tableColumns6,
      start6,
      listTotals6,
      dataMeta6,
      orderBy6,
      isSortDirDesc6,
      refListTable6,

      dataList7,
      tableColumns7,
      start7,
      listTotals7,
      dataMeta7,
      orderBy7,
      isSortDirDesc7,
      refListTable7,

      dataList8,
      tableColumns8,
      start8,
      listTotals8,
      dataMeta8,
      orderBy8,
      isSortDirDesc8,
      refListTable8,

      dataList9,
      tableColumns9,
      start9,
      listTotals9,
      dataMeta9,
      orderBy9,
      isSortDirDesc9,
      refListTable9,

      dataList10,
      tableColumns10,
      start10,
      listTotals10,
      dataMeta10,
      orderBy10,
      isSortDirDesc10,
      refListTable10,

      dataList11,
      tableColumns11,
      start11,
      listTotals11,
      dataMeta11,
      orderBy11,
      isSortDirDesc11,
      refListTable11,

      dataList12,
      tableColumns12,
      start12,
      listTotals12,
      dataMeta12,
      orderBy12,
      isSortDirDesc12,
      refListTable12,

      dataList13,
      tableColumns13,
      start13,
      listTotals13,
      dataMeta13,
      orderBy13,
      isSortDirDesc13,
      refListTable13,

      dataList14,
      tableColumns14,
      start14,
      listTotals14,
      dataMeta14,
      orderBy14,
      isSortDirDesc14,
      refListTable14,

      dataList15,
      tableColumns15,
      start15,
      listTotals15,
      dataMeta15,
      orderBy15,
      isSortDirDesc15,
      refListTable15,

      dataList16,
      tableColumns16,
      start16,
      listTotals16,
      dataMeta16,
      orderBy16,
      isSortDirDesc16,
      refListTable16,

      dataList17,
      tableColumns17,
      start17,
      listTotals17,
      dataMeta17,
      orderBy17,
      isSortDirDesc17,
      refListTable17,

      dataList18,
      tableColumns18,
      start18,
      listTotals18,
      dataMeta18,
      orderBy18,
      isSortDirDesc18,
      refListTable18,

      dataList19,
      tableColumns19,
      start19,
      listTotals19,
      dataMeta19,
      orderBy19,
      isSortDirDesc19,
      refListTable19,


      // Filter
      avatarText,
      getCodeOptions,
      toDate,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
